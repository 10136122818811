import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views

import Maps from "views/admin/Maps.js";
import Tables from "views/admin/Tables.js";
import HeaderStatsOthers from "components/Headers/HeaderStatsOthers";
import Users from "views/admin/users/Users";
import SuspendedUsers from "views/admin/users/SuspendedUsers";
import PendingUsers from "views/admin/users/PendingUsers";
import auth from "./../services/authService";
import NewShares from "views/admin/shares/NewShares";
import UserShares from "views/admin/shares/UserShares";
import Shares from "views/admin/shares/Shares";
import PendingShares from "views/admin/shares/PendingShares";
import NewLoan from "views/admin/loans/NewLoan";
import PendingLoansGuarantor from "views/admin/loans/GuarantorPendingLoans";
import LoansGuarantor from "views/admin/loans/Guarantor";
import PendingLoans from "views/admin/loans/PendingLoans";
import Loans from "views/admin/loans/Loans";
import UserLoans from "views/admin/loans/UserLoans";
import MakePayment from "views/admin/loanPayment/MakePayment";
import PendingLoanPayments from "views/admin/loanPayment/PendingLoanPayments";
import UserLoanPayments from "../views/admin/loanPayment/UserLoanPayments";
import LoanPayments from "views/admin/loanPayment/LoanPayments";
import SidebarUser from "components/Sidebar/SideBarUser";
import ChangePassword from "views/admin/User/ChangePassword";
import LoanDisbursement from "views/admin/loans/LoanDisbursement";
import { getUserDetails } from "services/userService";
import UpdateDetails from "views/admin/User/UpdateDetails";
import SharesReport from "views/admin/shares/SharesReport";
import PastDueLoans from "views/admin/loans/PastDueLoans";
import MonthlyReport from "views/admin/reports/monthlyReport";
import AnnualReport from "views/admin/reports/annualReport";

export default function AdminOthers() {
  let [userDetails, setUserDetails] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const { data: newData } = await getUserDetails();
      setUserDetails(newData[0]);
    };
    fetchData().catch(console.error);
  }, []);

  if (userDetails?.guarantor === "PENDING")
    return (window.location = "/user/updateDetails");

  const jwt = localStorage.getItem("eslSystem");
  if (!jwt) window.location = "#/auth/login";

  const user = auth.getCurrentUser();
  if (user && user?.exp === undefined) {
    localStorage.removeItem("eslSystem");
    window.location = "/auth/login";
  }

  if (user && user?.exp * 1000 < Date.now()) {
    localStorage.removeItem("eslSystem");
    window.location = "/auth/login";
  }
  if (!jwt) window.location = "/auth/login";

  return (
    <>
      {user?.user_type === "ADMIN" && <Sidebar />}
      {user?.user_type === "USER" && <SidebarUser />}{" "}
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar />
        {/* Header */}
        <HeaderStatsOthers />
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <Switch>
            <Route path="/admin/maps" exact component={Maps} />
            <Route
              path="/admin/updateDetails"
              exact
              component={UpdateDetails}
            />

            {user?.user_type === "USER" && (
              <>
                <Route path="/admin/newShares" exact component={NewShares} />
                <Route path="/admin/myShares" exact component={UserShares} />

                <Route path="/admin/newLoan" exact component={NewLoan} />
                <Route
                  path="/admin/pendingLoansGuarantor"
                  exact
                  component={PendingLoansGuarantor}
                />
                <Route
                  path="/admin/loansGuarantor"
                  exact
                  component={LoansGuarantor}
                />
                <Route path="/admin/myLoans" exact component={UserLoans} />

                <Route
                  path="/admin/makePayment"
                  exact
                  component={MakePayment}
                />
                <Route
                  path="/admin/myLoanPayments"
                  exact
                  component={UserLoanPayments}
                />

                <Route
                  path="/admin/changePassword"
                  exact
                  component={ChangePassword}
                />
              </>
            )}

            {user?.user_type === "ADMIN" && (
              <>
                <Route path="/admin/users" exact component={Users} />
                <Route
                  path="/admin/suspendedUsers"
                  exact
                  component={SuspendedUsers}
                />
                <Route
                  path="/admin/pendingUsers"
                  exact
                  component={PendingUsers}
                />

<Route path="/admin/monthlyReport" exact component={MonthlyReport} />
                <Route path="/admin/annualReport" exact component={AnnualReport} />
                <Route path="/admin/newShares" exact component={NewShares} />
                <Route path="/admin/myShares" exact component={UserShares} />
                <Route path="/admin/shares" exact component={Shares} />
                <Route
                  path="/admin/pendingShares"
                  exact
                  component={PendingShares}
                />
                <Route
                  path="/admin/sharesReport"
                  exact
                  component={SharesReport}
                />

                <Route path="/admin/newLoan" exact component={NewLoan} />
                <Route
                  path="/admin/pendingLoans"
                  exact
                  component={PendingLoans}
                />
                <Route
                  path="/admin/pastDueLoans"
                  exact
                  component={PastDueLoans}
                />
                <Route
                  path="/admin/pendingLoansGuarantor"
                  exact
                  component={PendingLoansGuarantor}
                />
                <Route
                  path="/admin/loansGuarantor"
                  exact
                  component={LoansGuarantor}
                />
                <Route path="/admin/loans" exact component={Loans} />
                <Route path="/admin/myLoans" exact component={UserLoans} />

                <Route
                  path="/admin/makePayment"
                  exact
                  component={MakePayment}
                />
                <Route
                  path="/admin/pendingLoanPayments"
                  exact
                  component={PendingLoanPayments}
                />
                <Route
                  path="/admin/loanPayments"
                  exact
                  component={LoanPayments}
                />
                <Route
                  path="/admin/loanDisbursement/:id"
                  exact
                  component={LoanDisbursement}
                />
                <Route
                  path="/admin/myLoanPayments"
                  exact
                  component={UserLoanPayments}
                />
                <Route
                  path="/admin/changePassword"
                  exact
                  component={ChangePassword}
                />
              </>
            )}

            <Route path="/admin/tables" exact component={Tables} />
            <Redirect from="/admin" to="/dashboard/dashboard" />
          </Switch>
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}

import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAnnualReport } from "services/reportService";

export default function AnnualReport() {
  const [reports, setReports] = useState([]);
  const [year, setYear] = useState("");
  const [error, setError] = useState("");

  const years = Array.from({ length: 3 }, (_, i) =>
    (new Date().getFullYear() - i).toString()
  );

  const fetchReport = async () => {
    if (!year) {
      setError("Please select a year.");
      return;
    }
    setError("");
    toast.info("Loading...");
    try {
        const { data } = await getAnnualReport({ year });
        console.log(data.additionalData.annualTotals.annual_total_interest)
      setReports(data); // Expecting an array
      toast.success("Report fetched successfully!");
    } catch (err) {
      console.error(err);
      toast.error("Failed to fetch the report.");
    }
  };

  return (
    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
      <div className="container mx-auto p-4">
        <div className="mb-4">
          <h1 className="text-2xl font-semibold">Annual Report</h1>
          <div className="flex items-center space-x-4 mt-4">
            <select
              className="border rounded px-4 py-2"
              value={year}
              onChange={(e) => setYear(e.target.value)}
            >
              <option value="">Select Year</option>
              {years.map((y) => (
                <option key={y} value={y}>
                  {y}
                </option>
              ))}
            </select>
            <button
              className="bg-blue-500 hover:bg-blue-700 px-4 py-2 rounded shadow-md"
              onClick={fetchReport}
            >
              Search
            </button>
          </div>
          {error && <p className="text-red-500 mt-2">{error}</p>}
              </div>
              
              <div className="mb-8 border p-4 rounded shadow-md">
  {/* Annual Totals */}
  <div className="mt-4">
    <h3 className="text-lg font-semibold">Annual Totals</h3>
    <table className="w-full border-collapse border border-gray-300 mt-2">
      <thead className="bg-gray-100">
        <tr>
          <th className="px-4 py-2 border border-gray-300">Total Shares Purchased</th>
          <th className="px-4 py-2 border border-gray-300">Total Accrued Interest</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="border px-4 py-2">{reports?.additionalData?.annualTotals.annual_total_shares}</td>
          <td className="border px-4 py-2">
            {reports?.additionalData?.annualTotals?.annual_total_interest.toLocaleString("en-US", {
              style: "currency",
              currency: "NGN",
            })}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  {/* <div className="mt-4">
    <h3 className="text-lg font-semibold">Monthly Breakdown</h3>
    <table className="w-full border-collapse border border-gray-300 mt-2">
      <thead className="bg-gray-100">
        <tr>
          <th className="px-4 py-2 border border-gray-300">Month</th>
          <th className="px-4 py-2 border border-gray-300">Shares Purchased</th>
          <th className="px-4 py-2 border border-gray-300">Accrued Interest</th>
        </tr>
      </thead>
      <tbody>
        {reports?.monthlyTotals?.map((monthlyData) => (
          <tr key={monthlyData?.month}>
            <td className="border px-4 py-2">{monthlyData?.month}</td>
            <td className="border px-4 py-2">{monthlyData?.total_shares_purchased}</td>
            <td className="border px-4 py-2">
              {monthlyData?.total_accrued_interest.toLocaleString("en-US", {
                style: "currency",
                currency: "NGN",
              })}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div> */}
</div>

        {reports?.report?.length > 0 ? (
          reports?.report.map((report, index) => (
            <div key={index} className="mb-8 border p-4 rounded shadow-md">
              <h2 className="text-xl font-semibold mb-4">
                {report?.name} ({report?.email})
              </h2>
              <p>
                <strong>Phone Number:</strong> {report?.phone_number}
              </p>
              <p>
                <strong>Account Name:</strong> {report?.account_name}
              </p>
              <p>
                <strong>Account Number:</strong> {report?.account_number}
              </p>
              <p>
                <strong>Bank Name:</strong> {report?.bank_name}
              </p>

              <div className="mt-4">
                <h3 className="text-lg font-semibold">Monthly Breakdown</h3>
                <table className="w-full border-collapse border border-gray-300 mt-2">
                  <thead className="bg-gray-100">
                    <tr>
                      <th className="px-4 py-2 border border-gray-300">Month</th>
                      <th className="px-4 py-2 border border-gray-300">Interest</th>
                      <th className="px-4 py-2 border border-gray-300">Shares</th>
                      <th className="px-4 py-2 border border-gray-300">Share Value</th>
                      <th className="px-4 py-2 border border-gray-300">
                        Monthly Addition
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(report?.months).map(([month, details]) => (
                      <tr key={month}>
                        <td className="border px-4 py-2">{month}</td>
                        <td className="border px-4 py-2">
                          {details?.interest?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "NGN",
                          })}
                        </td>
                        <td className="border px-4 py-2">{details?.shares}</td>
                        <td className="border px-4 py-2">
                          {details?.share_value?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "NGN",
                          })}
                        </td>
                        <td className="border px-4 py-2">
                          {details?.monthly_addition?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "NGN",
                          })}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="mt-4">
                <h3 className="text-lg font-semibold">Annual Totals</h3>
                <table className="w-full border-collapse border border-gray-300 mt-2">
                  <thead className="bg-gray-100">
                    <tr>
                      <th className="px-4 py-2 border border-gray-300">Total Shares</th>
                      <th className="px-4 py-2 border border-gray-300">
                        Total Share Value
                      </th>
                      <th className="px-4 py-2 border border-gray-300">Total Interest</th>
                      <th className="px-4 py-2 border border-gray-300">Combined Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border px-4 py-2">{report?.total.total_shares}</td>
                      <td className="border px-4 py-2">
                        {report?.total.total_share_value.toLocaleString("en-US", {
                          style: "currency",
                          currency: "NGN",
                        })}
                      </td>
                      <td className="border px-4 py-2">
                        {report?.total.interest.toLocaleString("en-US", {
                          style: "currency",
                          currency: "NGN",
                        })}
                      </td>
                      <td className="border px-4 py-2">
                        {report?.total.combined_total.toLocaleString("en-US", {
                          style: "currency",
                          currency: "NGN",
                        })}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ))
        ) : (
          <p className="text-center py-4">No data available for the selected year.</p>
        )}
      </div>
    </div>
  );
}

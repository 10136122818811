import http from "./httpService";

const apiEndpoint = "/reports";

export function getLoans() {
  return http.get(apiEndpoint);
}

export function getAnnualReport(filter) {
  return http.get(`${apiEndpoint}/annual/${filter.year}`);
}

export function getMonthlyReport(filter) {
    return http.get(`${apiEndpoint}/monthly/${filter.month}/${filter.year}`);
}

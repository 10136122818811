import React, { useState } from "react";
import { toast } from "react-toastify"; // Ensure you install react-toastify
import "react-toastify/dist/ReactToastify.css"; // Import toastify CSS
import { getMonthlyReport } from "services/reportService";

export default function MonthlyReport() {
  const [report, setReport] = useState([]);
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [error, setError] = useState("");

  const months = [
    { value: "1", label: "January" },
    { value: "2", label: "February" },
    { value: "3", label: "March" },
    { value: "4", label: "April" },
    { value: "5", label: "May" },
    { value: "6", label: "June" },
    { value: "7", label: "July" },
    { value: "8", label: "August" },
    { value: "9", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  const years = Array.from({ length: 3 }, (_, i) =>
    (new Date().getFullYear() - i).toString()
  );

  const fetchReport = async () => {
    if (!month || !year) {
      setError("Please select both month and year.");
      return;
    }
    setError("");
    toast.info("Loading...");
    try {
      const { data: newData } = await getMonthlyReport({ month, year });
      setReport(newData);
      toast.success("Report fetched successfully!");
    } catch (err) {
      console.error(err);
      toast.error("Failed to fetch the report.");
    }
  };

  const tableHeaders = [
    "Name",
    "Email",
    "Phone Number",
    "Account Name",
    "Account Number",
    "Bank Name",
    "User Total Shares",
    "User Total Shares Value",
    "Interest Per Share",
    "User Share Value",
    "Total User Value",
    "Total Accrued Interest",
    "Total Shares Purchased",
  ];

  return (
    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
      <div className="container mx-auto p-4">
        <div className="mb-4">
          <h1 className="text-2xl font-semibold">Monthly Report</h1>
          <div className="flex items-center space-x-4 mt-4">
            <select
              className="border rounded px-4 py-2"
              value={month}
              onChange={(e) => setMonth(e.target.value)}
            >
              <option value="">Select Month</option>
              {months.map((m) => (
                <option key={m.value} value={m.value}>
                  {m.label}
                </option>
              ))}
            </select>
            <select
              className="border rounded px-4 py-2"
              value={year}
              onChange={(e) => setYear(e.target.value)}
            >
              <option value="">Select Year</option>
              {years.map((y) => (
                <option key={y} value={y}>
                  {y}
                </option>
              ))}
            </select>
            <button
              className="bg-blue-500 hover:bg-blue-700 px-4 py-2 rounded shadow-md"
              onClick={fetchReport}
            >
              Search
            </button>
          </div>
          {error && <p className="text-red-500 mt-2">{error}</p>}
        </div>
        <div className="flex justify-between items-center mb-4">
          <p>Total Records: {report.length}</p>
        </div>
        <div
          className="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded"
          style={{ height: "400px", overflow: "auto" }}
        >
          <table className="w-full border-collapse border border-gray-300">
            <thead className="bg-gray-100">
              <tr>
                {tableHeaders.map((header) => (
                  <th
                    key={header}
                    className="px-4 py-2 border border-gray-300 text-left text-sm font-medium"
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {report.map((item, index) => (
                <tr key={index} className="hover:bg-gray-100">
                  <td className="border px-4 py-2">{item?.name}</td>
                  <td className="border px-4 py-2">{item?.email}</td>
                  <td className="border px-4 py-2">{item?.phone_number}</td>
                  <td className="border px-4 py-2">{item?.account_name}</td>
                  <td className="border px-4 py-2">{item?.account_number}</td>
                  <td className="border px-4 py-2">{item?.bank_name}</td>
                  <td className="border px-4 py-2">{item?.user_total_shares}</td>
                  <td className="border px-4 py-2">
                    {item?.user_total_shares_value?.toLocaleString("en-US")}
                  </td>
                  <td className="border px-4 py-2">
                    {item?.interest_per_share?.toFixed(2)}
                  </td>
                  <td className="border px-4 py-2">
                    {item?.total_interest?.toFixed(2)}
                  </td>
                  <td className="border px-4 py-2">
                    {item?.combined_total?.toFixed(2)}
                  </td>
                  <td className="border px-4 py-2">
                    {item?.total_accrued_interest?.toLocaleString("en-US")}
                  </td>
                  <td className="border px-4 py-2">{item?.total_shares_purchased}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {report.length === 0 && (
            <p className="text-center py-4">No data available for the selected month and year.</p>
          )}
        </div>
      </div>
    </div>
  );
}
